import {
  Box,
  BoxProps,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  ListItemProps,
  Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import Link from 'next/link';
import { motion, useMotionTemplate, useMotionValue } from 'framer-motion';
import { PricingPlan } from './PricingCards';

type PopularBadgeParams = {
  text: string;
};
const PopularBadge = ({ text }: PopularBadgeParams) => {
  return (
    <Box position='absolute' top='-16px' left='50%' style={{ transform: 'translate(-50%)' }}>
      <Text
        textTransform='uppercase'
        bg='bg-brand'
        px={3}
        py={1}
        color='white'
        fontSize='sm'
        fontWeight='600'
        rounded='xl'
      >
        {text}
      </Text>
    </Box>
  );
};

const PricingDetail = (props: ListItemProps) => {
  const { children, ...rest } = props;
  return (
    <ListItem {...rest}>
      <ListIcon as={FaCheckCircle} color='gray.400' />
      {children}
    </ListItem>
  );
};

interface ProductCardProps extends BoxProps {
  plan: PricingPlan;
  popular?: boolean;
  currentPlan?: boolean;
  href?: string;
  buttonText: string;
  cardBg?: string;
  cardShadow?: string;
}

export const PricingCard = (props: ProductCardProps) => {
  const { popular, currentPlan, href, buttonText, plan, cardBg, cardShadow, ...rest } = props;
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const glowOpacity = useMotionValue(useColorModeValue(0.1, 0.25));

  const handleMouseMove = ({ currentTarget, clientX, clientY }: MouseEvent) => {
    // @ts-ignore
    const { left, top } = currentTarget.getBoundingClientRect();

    mouseX.set(clientX - left);
    mouseY.set(clientY - top);
  };

  return (
    <Card
      role='group'
      as={motion.div}
      position='relative'
      whileHover={{ scale: 1.05 }}
      bg={cardBg}
      align='left'
      px={6}
      justify='left'
      my='5'
      borderRadius='2xl'
      // @ts-ignore
      onMouseMove={handleMouseMove}
      boxShadow={cardShadow}
    >
      <Box
        as={motion.div}
        opacity={0.8}
        position='absolute'
        pointerEvents='none'
        inset='1px'
        borderRadius='2xl'
        _groupHover={{ opacity: 1 }}
        transition='0.3s'
        className='transition duration-300'
        style={{
          background: useMotionTemplate`
            radial-gradient(
              550px circle at ${mouseX}px ${mouseY}px,
              hsla(283, 22%, 54%, ${glowOpacity}),
              transparent 80%
            )
          `
        }}
      />
      <Box py={4} {...rest}>
        {popular && <PopularBadge text='Most Popular' />}
        {currentPlan && <PopularBadge text='Current Plan' />}
      </Box>
      <CardHeader alignContent='start'>
        <Heading textAlign='left' fontWeight='500' size='md'>
          {plan.name}
        </Heading>
        <HStack justifyContent='left'>
          <Text fontSize='3xl' fontWeight='600'>
            $
          </Text>
          <Text fontSize='5xl' fontWeight='900'>
            {plan.price}
          </Text>
          <Text fontSize='3xl' color='gray.500'>
            /month
          </Text>
        </HStack>
      </CardHeader>

      <CardBody>
        <VStack borderBottomRadius='xl' alignItems='start'>
          <List spacing={3} textAlign='left'>
            {(plan.features || plan.features).map((feature, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <PricingDetail key={`${idx}-PricingDetail`}>{feature}</PricingDetail>
            ))}
          </List>
        </VStack>
      </CardBody>

      <CardFooter py={4} w='90%' pt={7} justifyContent='center' mx='auto' mb={10}>
        {href ? (
          <Link href={href} target='_self'>
            <Box display='flex' cursor='pointer'>
              <Button w='full' colorScheme='brand' variant='outline' isDisabled={currentPlan}>
                {currentPlan ? 'Current plan' : buttonText}
              </Button>
            </Box>
          </Link>
        ) : (
          <Button
            as={Link}
            href='/sign-up/'
            w='full'
            size='xl'
            colorScheme='brand'
            variant='solid'
            isDisabled={currentPlan}
          >
            {currentPlan ? 'Current plan' : buttonText}
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};
