import React from 'react';
import { Box, BoxProps, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { PricingCard } from './PricingCard';
import { PlanType } from '../../types';
import { AuthStatus, useAuthState } from '../../contexts/AuthContext';
import { useOrgState } from '../../contexts/OrgContext';

export type PricingPlan = {
  name: string;
  price: string;
  limit: number | string;
  features: string[];
  id?: string;
};

export enum PricingPlanName {
  // eslint-disable-next-line no-unused-vars
  FREE = 'Free',
  // eslint-disable-next-line no-unused-vars
  LAUNCHPAD = 'Launchpad',
  // eslint-disable-next-line no-unused-vars
  IGNITION = 'Ignition',
  // eslint-disable-next-line no-unused-vars
  TAKEOFF = 'Takeoff',
  // eslint-disable-next-line no-unused-vars
  ESCAPE_VELOCITY = 'Escape velocity',
  // eslint-disable-next-line no-unused-vars
  TO_THE_MOON = 'To the moon',
  // eslint-disable-next-line no-unused-vars
  ENTERPRISE = 'Enterprise'
}

const freeFeatures = [
  'Unlimited plans',
  'All supported Gateways',
  'Reporting & Analytics',
  'Subscription links',
  'KYC pages',
  'Custom branding',
  'Auto-retry and Dunning'
];
const paidFeatures = ['Remove CronPay branding', 'Webhooks'];

export const plans: Record<PricingPlanName, PricingPlan> = {
  [PricingPlanName.FREE]: {
    name: PricingPlanName.FREE,
    price: '0',
    limit: 20,
    features: ['Supports up to 20 subscribers', ...freeFeatures]
  },
  [PricingPlanName.LAUNCHPAD]: {
    name: PricingPlanName.LAUNCHPAD,
    price: '18',
    limit: 50,
    features: ['Supports up to 50 subscribers', ...freeFeatures, ...paidFeatures],
    id: process.env.NEXT_PUBLIC_PLAN_LAUNCHPAD
  },
  [PricingPlanName.IGNITION]: {
    name: PricingPlanName.IGNITION,
    price: '75',
    limit: 250,
    features: ['Supports up to 250 subscribers', ...freeFeatures, ...paidFeatures],
    id: process.env.NEXT_PUBLIC_PLAN_IGNITION
  },
  [PricingPlanName.TAKEOFF]: {
    name: PricingPlanName.TAKEOFF,
    price: '285',
    limit: 1000,
    features: ['Supports up to 1000 subscribers', ...freeFeatures, ...paidFeatures],
    id: process.env.NEXT_PUBLIC_PLAN_TAKEOFF
  },
  [PricingPlanName.ESCAPE_VELOCITY]: {
    name: PricingPlanName.ESCAPE_VELOCITY,
    price: '675',
    limit: 2500,
    features: ['Supports up to 2500 subscribers', ...freeFeatures, ...paidFeatures]
  },
  [PricingPlanName.TO_THE_MOON]: {
    name: PricingPlanName.TO_THE_MOON,
    price: '1,275',
    limit: 5000,
    features: ['Supports up to 5000 subscribers', ...freeFeatures, ...paidFeatures]
  },
  [PricingPlanName.ENTERPRISE]: {
    name: PricingPlanName.ENTERPRISE,
    price: 'TBC',
    limit: 'No more limits',
    features: ['No more limits', ...freeFeatures, ...paidFeatures, 'Custom reporting and analytics']
  }
};

const redirectUrlBase = process.env.NEXT_PUBLIC_URL;
const plansToShowcase = [
  plans[PricingPlanName.FREE],
  plans[PricingPlanName.LAUNCHPAD],
  plans[PricingPlanName.IGNITION]
];

const PricingCards = ({
  currentPlan,
  cardBg,
  cardShadow,
  signingUp = false,
  // buttonText = 'Sign Up',
  ...rest
}: {
  cardBg?: string;
  cardShadow?: string;
  currentPlan?: PlanType;
  signingUp?: boolean;
  buttonText?: string;
  rest?: BoxProps;
}) => {
  const { sessionStatus, user } = useAuthState();
  const { currentOrganisation } = useOrgState();

  const buildRedirectUrl = ({ isSuccess }: { isSuccess: boolean }) => {
    return `${redirectUrlBase}/dashboard/${currentOrganisation?.id || 'no-id'}/payment?isSuccess=${
      isSuccess ? '1' : '0'
    }`;
  };

  const buildQueryStringParams = ({ plan }: { plan: PricingPlan }): string => {
    if (sessionStatus !== AuthStatus.AUTHENTICATED || !currentOrganisation) {
      return '';
    }

    const params = {
      planId: plan.id,
      token: process.env.NEXT_PUBLIC_PLAN_MEDIUM_TOKEN,
      // @ts-ignore
      fName: currentOrganisation?.billing?.name || user.fName, // TODO: Fix type
      // @ts-ignore
      email: currentOrganisation?.billing?.email || user.email, // TODO: Fix type
      ref: currentOrganisation?.id || 'no-id',
      successUrl: buildRedirectUrl({ isSuccess: true }),
      cancelUrl: buildRedirectUrl({ isSuccess: false }),
      metadata: JSON.stringify({ orgId: currentOrganisation.id })
    };

    return new URLSearchParams(params).toString();
  };

  return (
    <Box py={{ base: 2, md: 12 }} px={{ base: 1, md: 12 }} {...rest}>
      <VStack spacing={2} textAlign='center'>
        <Heading as='h2' size='2l' fontSize='4xl' fontWeight='extrabold'>
          Transparent Pricing
        </Heading>
        <Text fontSize='lg' color='gray.500'>
          Start for FREE and scale as you grow
        </Text>
      </VStack>
      <SimpleGrid
        spacing={{ base: 4, sm: 10 }}
        py={10}
        templateColumns={{ base: 'repeat(auto-fill, minmax(250px, 1fr))', sm: 'repeat(auto-fill, minmax(350px, 1fr))' }}
      >
        {plansToShowcase.map((plan: PricingPlan, index: number) => (
          <PricingCard
            key={index}
            plan={plan}
            buttonText={plan.price === '0' ? 'Try for Free' : 'Get Started'}
            cardShadow={cardShadow}
            cardBg={cardBg}
            href={signingUp ? '' : `${process.env.NEXT_PUBLIC_CRONPAY_URL}?${buildQueryStringParams({ plan })}`}
          />
        ))}
      </SimpleGrid>

      <VStack spacing={2} textAlign='center'>
        <Heading as='h3' size='sm' fontSize='lg'>
          Looking for more subscriptions?
        </Heading>
        <Text fontSize='lg' color='gray.500'>
          Our standard plans scale up to 5,000 subscribers a month
        </Text>
      </VStack>
    </Box>
  );
};

export default PricingCards;
